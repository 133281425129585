import * as React from "react"
import { graphql } from "gatsby"
import { Layout } from "../../../components/layout"
import { CollectionProductListing } from "../../../components/collection-product-listing"
import { Seo } from "../../../components/seo"
import slugify from "@sindresorhus/slugify"
import { MoreButton } from "../../../components/more-button"

export default function CollectionHandleIndex({
  data: { products },
  pageContext: { title },
}) {
  return (
    <Layout>
      <Seo title={`Category: ${title}`} />
      <div className="container">
        <h1 className="title">{title}</h1>
      </div>
      <div className="container">
      <CollectionProductListing products={products.nodes} />
      {products.pageInfo.hasNextPage && (
        <MoreButton to={`/search?p=${slugify(title)}#more`}>
          More Products
        </MoreButton>
      )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($title: String!) {
    products: allShopifyCollection(
      filter: {title: {eq: $title}}
      sort: { fields: title, order: ASC }
      limit: 150
    ) {
      nodes {
        ...CollectionProductCard
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`
