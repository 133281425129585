import * as React from "react"
import { CollectionProductCard } from "./collection-product-card"
import { listingContainerStyle } from "./product-listing.module.css"

// To optimize LCP we mark the first product card as eager so the image gets loaded faster
export function CollectionProductListing({ products = [] }) {
  console.log(products)
  const collectionTitle = products[0].title;
  return (
    <div className={listingContainerStyle}>
      {products[0].products.map((p, index) => (
        <CollectionProductCard collectionTitle={collectionTitle} product={p} key={p.id} eager={index === 0} />
      ))}
    </div>
  )
}
